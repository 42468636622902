import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getBackLink, getHeaderTitles, getNextQuizLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage = 1, mySubpage = 4 }) => {
  const questionNumber = 1
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"ONE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "What did the Harvard Grant Study on the human experience find matters the most?"
        }
        answers={[
          "Mental health",
          "Relationships",
          "Financial stability",
          "Having children",
        ]}
        correctAnswerNumber={1}
        answerDetail={
          "Relationships can move someone in a different direction, for the better or for the worse. Coaches can be the most important influence on a girl’s life, the person who tips the scale in a positive direction."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 1, 4)
